.mobile-header {
  width: 100%;
  height: 131px;
  background: #222222;
  display: flex;
  box-sizing: border-box;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}
  

.mob-lang {
  .mob-en {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }

  .mob-ru {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFA800;
  }
}

.mobile-nav {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 30% 0;
  height: 45%;

  .mobile-nav-el {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #FFFFFF;
  }
}