.mobile-footer{
  height: 400px;
  width: 100%;
  background: #31414F;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .footer-text{
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
text-align: center;

color: #FFFFFF;
padding: 0px 32px;
  }
}