.about {
  background: #31414F;
  width: 100%;
  height: 290px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .title {
    margin-top: 80px;
    display: inline-block;
    font-style: normal;
    font-weight: 900;
    font-size: 1.75vw;
    line-height: 103.19%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  
  .desc{
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    .description {
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #FFFFFF;
      margin-bottom: 10px;
    }

  } 

}