.mobile-products {
  .mob-erp-desc {
    background: #00A9D7;
    width: 100%;
    height: 700px;
    margin-top: -10px;
    margin-bottom: -5px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 0px 20px;

    .mob-erp-desc-title {
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 21px;
      text-align: right;
      color: #FFFFFF;
    }

    .mob-erp-desc-info {
      margin-top: -12px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-align: right;
      color: #FFFFFF;
    }

    .mob-erp-desc-button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 139px;
      height: 40px;
      margin-top: 20px;
      background: #FFFFFF;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
      border-radius: 46px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      /* identical to box height, or 16px */

      text-align: center;

      color: #31414F;

      &:hover {
        background: #DDFBFF;
      }

      &:active {
        background: #72E6FF;
      }
    }

    .mob-erp-desc-functions {
      margin-top: -10px;

      .mob-erp-func-title {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 21px;
        text-align: center;

        color: #FFFFFF;
      }

      .mob-erp-func-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        height: 330px;
        align-items: stretch;

        .mob-erp-func-el {
          width: 33%;
          margin-bottom: 20px;

          .mob-el-title {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;

            color: #FFFFFF;
          }
        }
      }
    }
  }

  .mob-meta-desc {
    background: #EB7801;
    width: 100%;
    height: 780px;
    margin-top: -10px;
    margin-bottom: -5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 20px 8px;
    text-align: left;
    position: relative;
    box-sizing: border-box;


    .mob-meta-desc-title {
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 900;
      font-size: 18px;
      line-height: 21px;

      color: #FFFFFF;
    }

    .mob-meta-desc-info {
      margin-bottom: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #FFFFFF;
    }

    .mob-meta-desc-tasks {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */

      width: 90%;
      color: #FFFFFF;

      ul {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #FFFFFF;
        li{
          margin-bottom: 20px;
        }
      }
    }

    .mob-meta-desc-speed {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      /* identical to box height */


      color: #FFFFFF;
      width: 90%;
      margin-bottom: 24px;
    }

    .mob-meta-desc-footer {
      width: 90%;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;

      color: #FFFFFF;
    }

    .mob-meta-desc-button{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 139px;
      height: 40px;
      margin-top: 20px;
      background: #FFFFFF;
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
      border-radius: 46px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      /* identical to box height, or 16px */
      margin-left: 30vw;
      position: absolute;
      bottom: 30px;
      text-align: center;

      color: #31414F;

      &:hover {
        background: #DDFBFF;
      }

      &:active {
        background: #72E6FF;
      }
    }
  }
}