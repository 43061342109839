.documents {
  width: 100%;
  height: 405px;

  .documents-header {
    width: 100%;
    height: 108px;
    background: #222222;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    letter-spacing: 0.03em;
    color: #E0E5E7;
    text-transform: uppercase;
  }

  .documents-description {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    height: 387px;

    .doc-erp {
      width: 50%;
      background: #00A9D7;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .doc-erp-title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        text-align: right;
        color: #FFFFFF;
        margin-top: 3%;
      }

      .doc-erp-list {
        display: flex;
        flex-direction: column;

        .doc-erp-list-el {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .doc-erp-list-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
          }

          img {
            cursor: pointer;
          }
        }
      }
    }

    .doc-meta {
      width: 50%;
      background: #EB7801;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .doc-meta-title {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        text-align: right;
        color: #FFFFFF;
        margin-top: 3%;
      }

      .doc-meta-list {
        display: flex;
        flex-direction: column;

        .doc-meta-list-el {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .doc-meta-list-title {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            text-align: center;
            color: #FFFFFF;
          }

          img {
            cursor: pointer;
          }
        }
      }
    }
  }
}