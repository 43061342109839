.mobile-media-lib {
  width: 100%;
  height: 450px;
  background: #31414F;
  margin-top: 87px;

  .mobile-media-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: #E0E5E7;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .mob-tabs-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 55px;

  }

.swiper{
  margin-top: -20px;
  height: 60%;
}
.swiper-pagination{
  top: 215px
}

.swiper-button-prev{
  margin-top: 70px;
  width: 40px;
  color: #D9D9D9
}

.swiper-button-next{
  margin-top: 70px;
  width: 40px;
  color: #D9D9D9
}

.swiper-pagination-bullet-active{
  background-color: #D9D9D9;
}
}

