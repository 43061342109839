.news-overlay {
  visibility: hidden;
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(15, 37, 56, 0.7);
  transition: opacity 500ms;
  z-index: 30000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-popup {
  width: 80%;
  height: 90%;
  background: #EDEDED;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-left: 20px;
  box-sizing: border-box;
  padding-bottom: 30px;

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
  }
  overflow-y: scroll;

}


.firstNews {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #140202;
  margin-top: 10px;
  width: 90%;
}

.news-popup-title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #31414F;
  text-align: left;
  margin-top: 30px;
  width: 94%;

}

.news-popup-date {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  margin-top: 10px;
  color: #858585;
}