.header{
  background: #222222;
  height: 131px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  z-index: 1000;

  .header-list{
    display: flex;
    width: 50%;
    justify-content: space-evenly;

    .header-list-element{
      text-transform: uppercase;
      cursor: pointer;
      color: white;
      font-style: normal;
      font-weight: 400;
      font-size: 0.9vw;
      line-height: 19px;

      &:hover{
        color: #E9DF8C;
      }
    }
  }

  .lang{
    .ru{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #FFA800;
    }

    .en{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: white;
    }
  }
}