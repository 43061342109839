.mobile-news {
  width: 100%;
  height: 720px;
  background: #31414F;
  box-sizing: border-box;
  padding-top: 28px;


  .mobile-news-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    letter-spacing: 0.03em;
    color: #E0E5E7;
    text-transform: uppercase;
  }

.swiper{
  height: 94%;
}
.swiper-pagination{
  top: 591px;
}

.swiper-button-prev{
  margin-top: 260px;
  width: 40px;
  color: #D9D9D9
}

.swiper-button-next{
  margin-top: 260px;
  width: 40px;
  color: #D9D9D9
}

.swiper-pagination-bullet-active{
  background-color: #D9D9D9
}
}
