.products {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #EB7801;

  .erp {
    width: 50%;
    position: relative;

    .erp-description {
      margin-top: -5px;
      width: 100%;
      min-height: 830px;
      background: #00A9D7;
 
      padding: 20px 0px;

      box-sizing: border-box;



      .erp-description-title {
        padding-left: 12.5%;
        padding-right: 40px;
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        color: #FFFFFF;
        margin-bottom: 20px;
      }

      .erp-bigger-than {
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 20px;

        .erp-bigger-than-title {
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 38px;
          text-align: right;
          color: #FFFFFF;
        }

        .erp-bigger-than-desc {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          text-align: right;
          color: #FFFFFF;
        }
      }

      .erp-functions {
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 15px;

        .erp-functions-list {
          display: flex;
          flex-wrap: wrap;
          margin: 12px 0px;
          justify-content: flex-end;
          margin-top: 20px;


          .list-element {
            width: 120px;
            margin-top: 10px;

            .list-title {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 16px;
              text-align: center;

              color: #FFFFFF;
            }
          }

        }

        .erp-functions-title {
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 38px;
          text-align: right;
          color: #FFFFFF;
        }
      }

      .others {
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 15px;

        .others-title {
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 38px;
          color: #FFFFFF;
          text-align: right;
        }

        .act {
          width: 60%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin: 16px 0;

          .act-title {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            text-align: right;
          }

          .act-desc {
            text-align: right;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
          }
        }
      }

      .effective {
        padding-right: 40px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        color: #FFFFFF;
      }
    }
  }

  .meta {
    width: 50%;
    position: relative;

    .meta-description {
      box-sizing: border-box;
      margin-top: -4.9px;
      width: 100%;
      min-height: 830px;
      background: #EB7801;
      display: flex;
      padding: 32px 40px;
      flex-direction: column;
      align-items: flex-start;


      .meta-title {
        font-style: normal;
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;

        color: #FFFFFF;
      }

      .meta-title-desc {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #FFFFFF;
      }

      .meta-tasks {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 24px;

        .meta-tasks-title {
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 38px;
          color: #FFFFFF;
        }

        .meta-tasks-list {

          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;

          ul {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-left: -24px;

            li {
              text-align: left;
              margin-bottom: 12px;
            }
          }
        }

      
      }
      .speed {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 24px;

        .speed-title {
          font-style: normal;
          font-weight: 400;
          font-size: 32px;
          line-height: 38px;
          color: #FFFFFF;
        }

        .speed-desc {
          margin-top: 12px;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #FFFFFF;
          text-align: left;
        }
      }
    }
  }
}

.href-button {
  width: 139px;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #31414F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  position: absolute;
  bottom: 19px;


  &:hover {
    background: #DDFBFF;
  }

  &:active {
    background: #72E6FF;
  }
}

.href-button2 {
  width: 139px;
  height: 40px;
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 46px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #31414F;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 20px;
  margin-right: 40px;


  &:hover {
    background: #DDFBFF;
  }

  &:active {
    background: #72E6FF;
  }
}

.btncont{
  width: 100%;
  display: flex;
  justify-content: right;
  
}