.news {
  width: 100%;
  height: 816px; //816px
  background: #31414F;
  box-sizing: border-box;

  .news-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    letter-spacing: 0.03em;
    color: #E0E5E7;
    padding-top: 40px;
    padding-bottom: 40px;
    text-transform: uppercase;
  }

  .swiper{
    width: 96%;

  }

  // @media (width: 1920px){
  //   .swiper{
  //     padding-left: 3vw;
  
  //   }
  // }

  // @media (max-width: 1600px){
  //   .swiper{
  //     padding-left: 16%;
  
  //   }
  // }

  // @media (max-width: 1440px){
  //   .swiper{
  //     padding-left: 14.5%;
  
  //   }
  // }

  // @media (width: 960){
  //   .swiper{
  //     padding-left: 5%;
  
  //   }
  // }

  // @media (width: 720px){
  //   .swiper{
  //     padding-left: 21%;
  
  //   }
  // }

  // @media (max-width: 823px){
  //   .swiper{
  //     padding-left: 24%;
  
  //   }
  // }

  // @media (max-width: 1152px){
  //   .swiper{
  //     padding-left: 9%;
  
  //   }
  // }

  // @media (width: 2880px){
  //   .swiper{
  //     padding-left: 15%;
  
  //   }
  // }

  // @media (width: 4320px){
  //   .swiper{
  //     padding-left: 18%;
  
  //   }
  // }
  // .swiper-pagination{
  //   top: 591px;
  // }
  
  .swiper-button-prev{
    
    color: #D9D9D9
  }
  
  .swiper-button-next{
  
    color: #D9D9D9
  }
  
  .swiper-pagination-bullet-active{
    background-color: #D9D9D9
  }
 
}



