.mobile-news-overlay {
  visibility: hidden;
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(15, 37, 56, 0.7);
  transition: opacity 500ms;
  z-index: 30000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-news-popup {
  width: 95%;
  max-height: 95%;
  min-height: 40%;
  background: #EDEDED;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding-left: 20px;
  box-sizing: border-box;
  

  .mobile-close {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    cursor: pointer;

  }

  overflow-y: scroll ;
  padding-bottom: 30px;
  padding-right: 10px;

}


.mobile-firstNews {

  margin-top: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #140202;
}

.mobile-news-popup-title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  margin-top: 50px;
  color: #31414F;
  text-align: left;


}

.mobile-news-popup-date {

  margin-top: 5px;

  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 13px;
  color: #858585;
}