.overlay {
  visibility: hidden;
  opacity: 0.5;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(15, 37, 56, 0.7);
  transition: opacity 500ms;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-popup {
  width: 374px;
  height: 616px;
  background: #E0E5E7;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;

  .mobile-popup-title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #31414F;
    width: 70%;
    margin-top: 10px;
  }

  .mobile-popup-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    


    .mobile-popup-inputs {
      width: 95%;
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 20px;

      .mobile-custom-input {
        background: #FFFFFF;
        border-radius: 38px;
        width: 390px;
        height: 48px;
        border: none;
        padding-left: 30px;
        box-sizing: border-box;

        &:focus {
          outline: none;
        }
      }


    }

    .mobile-popup-textarea {

      .mobile-custom-textarea {
        background: #FFFFFF;
        border-radius: 14px;
        border: none;
        width: 350px;
        height: 286px;
        padding-left: 30px;
        padding-top: 20px;
        box-sizing: border-box;
        resize: none;
        font-family: Arial, Helvetica, sans-serif;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .mobile-form-button {
    width: 218px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #31414F;
    cursor: pointer;

    &:hover {
      background: #DDFBFF;
    }

    &:active {
      background: #72E6FF;
    }
  }
}