.card {
  width: 409px;
  height: 613px;
  background: #E0E5E7;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;

  .card-img {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    margin-top: -1px;
    width: 409px;
    height: 231px;
  }

  .card-date {
    display: flex;
    justify-content: flex-end;
    padding-right: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #31414F;
  }

  .card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;

    color: #31414F;
    padding: 0 24px;
    position: absolute;
    top: 270px;
  }

  .card-description {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    text-align: left;
    padding-left: 20px;
    position: absolute;
    top: 370px;

  }

  .card-button {
    width: 139px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 46px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #31414F;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 490px;

    &:hover {
      background: #DDFBFF;
    }

    &:active {
      background: #72E6FF;
    }
  }

  .card-feedback {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 52px;
    background: #FFFFFF;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;

    .feedback-section {
      display: flex;
      width: 35px;
      justify-content: space-between;
      align-items: center;
    }

    .feedback-section2 {
      display: flex;
      width: 150px;
      justify-content: space-between;
      align-items: center;
    }

  }
}

