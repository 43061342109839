.footer{
  width: 100%;
  height: 400px;
  background: #31414F;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  .footer-info{
    width: 30%;
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
color: #FFFFFF;
  }

  .socials{
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .rights{
    font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 14px;
text-transform: capitalize;

color: #FFFFFF;
  }
}