.media-lib {
  width: 100%;
  height: 573px;
  background: #31414F;
  box-sizing: border-box;
  margin-top: 80px;

  .tabs-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 55px;
  }

  .media-lib-header {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    letter-spacing: 0.03em;
    color: #E0E5E7;
    text-transform: uppercase;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .swiper{
    // padding-left: 160px;

  }
  // .swiper-pagination{
  //   top: 591px;
  // }
  
  .swiper-button-prev{
    
    color: #D9D9D9
  }
  
  .swiper-button-next{
  
    color: #D9D9D9
  }
  
  .swiper-pagination-bullet-active{
    background-color: #D9D9D9
  }
}