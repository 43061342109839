.mobile-contacts {
  width: 100%;
  height: 549px;
  background: url('../../../../assets/img/contactsBack.png');
  margin-top: -2px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .mob-cont-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: #E0E5E7;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .mob-cont-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
    text-align: left;
    padding-left: 20px;
  }

  .mob-cont-button {
    width: 218px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 28px;

    &:hover {
      background: #DDFBFF;
    }

    &:active {
      background: #72E6FF;
    }
  }
}