.mobile-about {
  width: 100%;
  height: 330px;
  background: #31414F;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px 25px;
  box-sizing: border-box;
  

  .mobile-about-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #FFFFFF;
  }

  .mobile-about-desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    text-align: center;
    color: #FFFFFF;
  }
}