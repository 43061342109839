.contacts {
  width: 100%;
  height: 611px;
  background: url('../../../assets/img/contactsBack.png');

  .contacts-title {
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    letter-spacing: 0.03em;
    color: #E0E5E7;
    text-transform: uppercase;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .contacts-info {
    display: flex;
    justify-content: space-evenly;

    .map{
      width: 40%;
      margin-left: 40px;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .info-desc {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        text-transform: uppercase;
        color: #FFFFFF;
        margin-bottom: 20px;

      }

      .info-feedback {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        color: #FFFFFF;
        margin-bottom: 30px;

      }

      .contacts-button {
        background: #FFFFFF;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
        border-radius: 46px;
        height: 40px;
        width: 218px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
          background: #DDFBFF;
        }

        &:active {
          background: #72E6FF;
        }
      }
    }
  }
}