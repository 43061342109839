.mobile-card {
  width: 350px;
  height: 535px;
  background: #E0E5E7;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  margin-top: 20px;
  position: relative;

  .mobile-card-img {
    width: 100%;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    width: 350px;
    height: 200px;
  }

  .mobile-card-date {
    text-align: right;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    color: #31414F;
    padding-right: 5px;
  }

  .mobile-card-title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #31414F;
    margin: 10px 0px;
    padding: 0px 40px;
  }

  .mobile-card-description {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    text-align: left;
    padding-left: 20px;

    
  }
  .mobile-card-button {
    width: 139px;
    height: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
    border-radius: 46px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #31414F;
    margin-left: 30%;

    position: absolute;
    bottom: 20px;

    &:hover {
      background: #DDFBFF;
    }

    &:active {
      background: #72E6FF;
    }
  }
}

