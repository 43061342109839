.mobile-documents {
  width: 100%;
  height: 594px;
  margin-top: -2px;

  .mob-doc-header {
    height: 108px;
    background: #222222;
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 103.19%;
    text-transform: uppercase;
    letter-spacing: 0.03em;
    color: #E0E5E7;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  .mob-doc-desc1 {
    height: 333px;
    background: #00A9D7;
  }

  .mob-doc-desc2 {
    height: 243px;
    background: #EB7801;
  }
}

.mob-doc-desc-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 10px;
}

.mob-download {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 5px;
  padding-right: 10px;

  a {
    margin-bottom: -5px;
  }
}

.down-desc {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  text-align: left;
}